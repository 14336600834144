import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const BRANDS_LIST = "brands_list";
export const BRANDS_SIMPLE_LIST = "brands_simple_list";
export const CREATE_BRAND = "create_brand";
export const UPDATE_BRAND = "update_brand";
export const UPDATE_BRAND_STATUS = "UPDATE_BRAND_STATUS";
export const DELETE_BRAND = "delete_brand";
export const GET_BRAND_DETAILS = "get_brand_details";
export const CLEAR_BRAND_ERRORS = "clearBrandErrors";

// mutation types
export const SET_BRAND_ERROR = "setBrandError";
export const REMOVE_BRAND_ERRORS = "removeBrandErrors";

const state = {
    errors: null
};


const actions = {
    [BRANDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/brands?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BRAND_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [BRANDS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/brandssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_BRAND_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getbranddetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BRAND_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [CREATE_BRAND](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createbrand", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRAND_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BRAND](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebrand", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRAND_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BRAND_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebrandstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRAND_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [DELETE_BRAND](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletebrand", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRAND_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRAND_ERROR, response.errors);
                });
        });
    },
    [CLEAR_BRAND_ERRORS](context) {
        context.commit(REMOVE_BRAND_ERRORS);
    },
};

const mutations = {
    [SET_BRAND_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_BRAND_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
