import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DASHBOARD_STATS = "DASHBOARD_STATS";
export const DASHBOARD_REQS = "DASHBOARD_REQS";
export const CLEAR_DASHBOARD_ERRORS = "clearBrandsErrors";

// mutation types
export const SET_DASHBOARD_ERROR = "setBrandsError";
export const REMOVE_DASHBOARD_ERRORS = "removeBrandsErrors";

const state = {
    errors: null
};
const actions = {
    [DASHBOARD_STATS](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/dashboardstats")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_REQS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/dashboardreqs?date=" + payload.date)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DASHBOARD_ERRORS](context) {
        context.commit(REMOVE_DASHBOARD_ERRORS);
    },
};

const mutations = {
    [SET_DASHBOARD_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DASHBOARD_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
