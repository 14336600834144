import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const REPORTS_LIST = "reports_list";
export const REPORTS_SIMPLE_LIST = "reports_simple_list";
export const CREATE_REPORT = "create_report";
export const UPDATE_REPORT = "update_report";
export const UPDATE_REPORT_STATUS = "UPDATE_REPORT_STATUS";
export const DELETE_REPORT = "delete_report";
export const GET_REPORT_DETAILS = "get_report_details";
export const CLEAR_REPORT_ERRORS = "clearReportErrors";

// mutation types
export const SET_REPORT_ERROR = "setReportError";
export const REMOVE_REPORT_ERRORS = "removeReportErrors";

const state = {
    errors: null
};


const actions = {
    [REPORTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/reports?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_REPORT_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [REPORTS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/reportssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_REPORT_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getreportdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_REPORT_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [CREATE_REPORT](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createreport", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_REPORT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [UPDATE_REPORT](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatereport", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_REPORT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [UPDATE_REPORT_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatereportstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_REPORT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [DELETE_REPORT](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletereport", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_REPORT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_REPORT_ERROR, response.errors);
                });
        });
    },
    [CLEAR_REPORT_ERRORS](context) {
        context.commit(REMOVE_REPORT_ERRORS);
    },
};

const mutations = {
    [SET_REPORT_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_REPORT_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
