export const SET_CONFIRMATION = "setConfirmation";
export const MUT_CONFIRMATION = "mutConfirmation";
const state = {
    confirm: {
        title: '',
        msg: '',
        clr: '',
        callback: null
    },
};
const getters = {
    getConfirm(state) {
        return state.confirm;
    }
};
const actions = {
    [SET_CONFIRMATION](context, payload) {
        context.commit(MUT_CONFIRMATION, payload);
    }
};
const mutations = {
    [MUT_CONFIRMATION](state, confirm) {
        state.confirm = confirm;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};