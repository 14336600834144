import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const BANNERS_LIST = "banners_list";
export const BANNERS_SIMPLE_LIST = "banners_simple_list";
export const CREATE_BANNER = "create_banner";
export const UPDATE_BANNER = "update_banner";
export const UPDATE_BANNER_STATUS = "UPDATE_BANNER_STATUS";
export const DELETE_BANNER = "delete_banner";
export const GET_BANNER_DETAILS = "get_banner_details";
export const CLEAR_BANNER_ERRORS = "clearBannerErrors";

// mutation types
export const SET_BANNER_ERROR = "setBannerError";
export const REMOVE_BANNER_ERRORS = "removeBannerErrors";

const state = {
    errors: null
};


const actions = {
    [BANNERS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/banners?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BANNER_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [BANNERS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/bannerssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_BANNER_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getbannerdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BANNER_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [CREATE_BANNER](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createbanner", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BANNER_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BANNER](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebanner", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BANNER_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BANNER_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebannerstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BANNER_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [DELETE_BANNER](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletebanner", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BANNER_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BANNER_ERROR, response.errors);
                });
        });
    },
    [CLEAR_BANNER_ERRORS](context) {
        context.commit(REMOVE_BANNER_ERRORS);
    },
};

const mutations = {
    [SET_BANNER_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_BANNER_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
