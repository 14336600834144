export const SET_SNACK = "setSnack";
export const MUT_SNACK = "mutSnack";
const state = {
  snack: {
    msg: '',
    clr: ''
  },
};
const getters = {
  getSnack(state) {
    return state.snack;
  }
};
const actions = {
  [SET_SNACK](context, payload) {
    context.commit(MUT_SNACK, payload);
  }
};
const mutations = {
  [MUT_SNACK](state, snack) {
    state.snack = snack;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};