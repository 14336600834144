import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import store from "@/core/services/store";
import AuthService from "@/core/services/auth.service";
import ApiService from "@/core/services/api.service";
import auth from "@/core/services/store/auth.module";
import {LOGOUT} from "@/core/services/store/auth.module";
// import MockService from "@/core/mock/mock.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import Editor from "@tinymce/tinymce-vue";
import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import {getToken} from "./core/services/jwt.service";
import * as VueGoogleMaps from "vue2-google-maps"; // Import package

// import mixins
import globalMixin from "./mixins/globalMixin";

Vue.component("editor", Editor);
Vue.component("quillEditor", quillEditor);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAEzhShVbsRuoVYbBsw_T5DotzFHjDnVrA",
        libraries: "places"
    }
});
Vue.mixin(globalMixin);
ApiService.init();
// Remove this to disable mock API
// MockService.init();
router.beforeEach((to, from, next) => {
    if (!getToken() && auth.state.isAuthenticated) {
        store.dispatch(LOGOUT).then(() => router.push("login"));
        return false;
    }
    // Ensure we checked auth before each page load.
    Promise.all([
        store.dispatch(VERIFY_AUTH, to.name).then(data => {
            if (data && data._metadata.outcomeCode === 0) {
                if (to.meta.auth) {
                    if (AuthService.hasPermission(to.meta.permission)) {
                        next();
                    } else {
                        router.push("login");
                    }
                }
            }
            // console.log(AuthService.hasPermission(to.meta.permission));
        })
    ]).then(next);

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export const bus = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
