import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const TESTIMONIALS_LIST = "testimonials_list";
export const TESTIMONIALS_SIMPLE_LIST = "testimonials_simple_list";
export const CREATE_TESTIMONIAL = "create_testimonial";
export const UPDATE_TESTIMONIAL = "update_testimonial";
export const UPDATE_TESTIMONIAL_STATUS = "UPDATE_TESTIMONIAL_STATUS";
export const DELETE_TESTIMONIAL = "delete_testimonial";
export const GET_TESTIMONIAL_DETAILS = "get_testimonial_details";
export const CLEAR_TESTIMONIAL_ERRORS = "clearTestimonialErrors";

// mutation types
export const SET_TESTIMONIAL_ERROR = "setTestimonialError";
export const REMOVE_TESTIMONIAL_ERRORS = "removeTestimonialErrors";

const state = {
    errors: null
};


const actions = {
    [TESTIMONIALS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/testimonials?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [TESTIMONIALS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/testimonialssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_TESTIMONIAL_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/gettestimonialdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [CREATE_TESTIMONIAL](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createtestimonial", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [UPDATE_TESTIMONIAL](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatetestimonial", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [UPDATE_TESTIMONIAL_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatetestimonialstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [DELETE_TESTIMONIAL](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletetestimonial", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TESTIMONIAL_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TESTIMONIAL_ERROR, response.errors);
                });
        });
    },
    [CLEAR_TESTIMONIAL_ERRORS](context) {
        context.commit(REMOVE_TESTIMONIAL_ERRORS);
    },
};

const mutations = {
    [SET_TESTIMONIAL_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_TESTIMONIAL_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
