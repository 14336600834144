import store from "@/core/services/store";

const AuthService = {
  // hasRole(role) {
  //   if (store.state.auth.roles.length !== 0) {
  //     return store.state.auth.roles.some(spec => {
  //       return spec === role;
  //     });
  //   }
  // },

  hasPermission(permission) {
    if (store.state.auth.permissions.length !== 0) {
      return store.state.auth.permissions.some(spec => {
        return spec === permission;
      });
    }
  }
};

export default AuthService;
