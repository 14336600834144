import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const TITLES_LIST = "titles_list";
export const TITLES_SIMPLE_LIST = "titles_simple_list";
export const CREATE_TITLE = "create_title";
export const UPDATE_TITLE = "update_title";
export const UPDATE_TITLE_STATUS = "UPDATE_TITLE_STATUS";
export const DELETE_TITLE = "delete_title";
export const GET_TITLE_DETAILS = "get_title_details";
export const CLEAR_TITLE_ERRORS = "clearTitleErrors";

// mutation types
export const SET_TITLE_ERROR = "setTitleError";
export const REMOVE_TITLE_ERRORS = "removeTitleErrors";

const state = {
    errors: null
};


const actions = {
    [TITLES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/titles?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_TITLE_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [TITLES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/titlessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_TITLE_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/gettitledetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_TITLE_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [CREATE_TITLE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createtitle", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TITLE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [UPDATE_TITLE](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatetitle", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TITLE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [UPDATE_TITLE_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatetitlestatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TITLE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [DELETE_TITLE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletetitle", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_TITLE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_TITLE_ERROR, response.errors);
                });
        });
    },
    [CLEAR_TITLE_ERRORS](context) {
        context.commit(REMOVE_TITLE_ERRORS);
    },
};

const mutations = {
    [SET_TITLE_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_TITLE_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
